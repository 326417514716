import { Controller } from 'stimulus';

export default class extends Controller {
  addBookingDomainIdListener() {
    this.domainIdInput = document.getElementById('booking_domain_id');
    if (this.domainIdInput) {
      this.domainIdInputEventListener = this.domainIdInput.addEventListener('change', this.handleChange);
    }
  }

  addFacilityIdListener() {
    this.facilityIdInput = document.getElementById('booking_facility_id');
    if (this.facilityIdInput) {
      this.facilityIdInputEventListener = this.facilityIdInput.addEventListener('change', this.handleChange);
    }
  }

  connect() {
    this.addBookingDomainIdListener();
    this.addFacilityIdListener();
  }

  handleChange = () => {
    this.fetchUrl = 'confirm_form_partial?';
    this.modelParams = [];
    if (this.domainIdInput.value !== '') { this.modelParams.push(`domain_id=${this.domainIdInput.value}`); }
    if (this.facilityIdInput.value !== '') { this.modelParams.push(`facility_id=${this.facilityIdInput.value}`); }
    this.fetchUrl += this.modelParams.join('&');

    fetch(this.fetchUrl)
      .then((response) => {
        if (!response.ok) { throw response; }
        return response.text();
      })
      .then((html) => {
        this.element.innerHTML = html;
        this.addBookingDomainIdListener();
        this.addFacilityIdListener();
      })
      .catch((error) => {
        console.error(error);
        this.h3 = document.createElement('h3');
        this.h3.classList.add('text-danger', 'text-center');
        this.h3.id = 'booking_domain_attributes_id';
        this.h3.innerHTML = 'Failed to load form';
        document.getElementById('booking_domain_attributes_id').closest('.card-body').innerHTML = this.h3.outerHTML;
      });
  }
}
