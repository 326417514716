import { Controller } from 'stimulus';

export default class extends Controller {
  insertValue(event) {
    event.preventDefault();
    this.inputGroup = event.target.closest('.input-group').firstElementChild;
    this.inputGroup.value = event.target.innerText;
    this.inputGroup.dispatchEvent(new Event('change'));
  }
}
