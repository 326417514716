import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    flatpickr(this.inputTarget, {
      allowInput: true,
      allowInvalidPreload: true,
      altInput: true,
      defaultDate: this.data.get('time'),
      disableMobile: 'true',
    });
  }
}
