import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['idField', 'labelField', 'loadingIcon', 'resultsTable', 'searchError', 'searchForm'];

  connect() {
    $('#consignor-search-modal').on('shown.bs.modal', () => {
      document.getElementById('keywords_name').focus();
    });
    $('#consignor-search-modal').on('hidden.bs.modal', () => {
      this.resultsTableTarget.hidden = true;
    });
  }

  clearConsignor(event) {
    event.preventDefault();
    this.labelFieldTarget.value = 'Select consignor...';
    this.idFieldTarget.value = '';
    $('#consignor-search-modal').modal('hide');
  }

  searchConsignors(event) {
    event.preventDefault();
    this.searchErrorTarget.hidden = true;
    this.resultsTableTarget.hidden = true;
    const formData = new FormData(this.searchFormTarget);
    const searchParams = new URLSearchParams(formData);
    const url = `/admin/search/consignors?${searchParams}`;
    let paramCount = 0;
    searchParams.forEach((value, key) => {
      if (value.length > 2 || (key === 'keywords[id]' && value.length > 0)) paramCount += 1;
    });
    if (paramCount !== 0) {
      this.loadingIconTarget.hidden = false;

      fetch(url)
        .then(response => response.text())
        .then((html) => {
          this.resultsTableTarget.innerHTML = html;
          this.loadingIconTarget.hidden = true;
          this.resultsTableTarget.hidden = false;
        });
    } else {
      this.searchErrorTarget.hidden = false;
    }
  }

  selectConsignor(event) {
    const consignorDataset = event.currentTarget.dataset;
    this.idFieldTarget.value = consignorDataset.recordId;
    this.labelFieldTarget.value = consignorDataset.recordName;
    $('#consignor-search-modal').modal('hide');
  }
}
