import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['idField', 'labelField', 'loadingIcon', 'resultsTable', 'searchError', 'searchForm'];

  connect() {
    $('#facility-search-modal').on('shown.bs.modal', () => {
      document.getElementById('keywords_name').focus();
    });
  }

  clearFacility(event) {
    event.preventDefault();
    this.labelFieldTarget.value = 'Select facility...';
    this.idFieldTarget.value = '';
    this.idFieldTarget.dispatchEvent(new Event('change'));
    $('#facility-search-modal').modal('hide');
  }

  searchFacilities(event) {
    event.preventDefault();
    this.searchErrorTarget.hidden = true;
    const formData = new FormData(this.searchFormTarget);
    const searchParams = new URLSearchParams(formData);
    const url = `/admin/search/facilities?${searchParams}`;
    let paramCount = 0;
    searchParams.forEach((value, key) => { if (value.length > 2 || (key.includes('state') && value.length === 2)) { paramCount += 1; } });
    if (paramCount !== 0) {
      this.loadingIconTarget.hidden = false;

      fetch(url)
        .then(response => response.text())
        .then((html) => {
          this.resultsTableTarget.innerHTML = html;
          this.loadingIconTarget.hidden = true;
          this.resultsTableTarget.hidden = false;
        });
    } else {
      this.searchErrorTarget.hidden = false;
    }
  }

  selectFacility(event) {
    const facilityDataset = event.currentTarget.dataset;
    this.labelFieldTarget.value = facilityDataset.recordName;
    this.idFieldTarget.value = facilityDataset.recordId;
    this.idFieldTarget.dispatchEvent(new Event('change'));
    $('#facility-search-modal').modal('hide');
  }
}
