import UploadsController from '../uploads_controller';

export default class extends UploadsController {
  attach(id) {
    const signedBlobIds = document.querySelectorAll('input[name="image[upload_attachments][]"][type="hidden"]');
    const signedBlobId = signedBlobIds[id - 1];
    const imageData = { upload: { signedBlobId: signedBlobId.value } };
    const progress = document.getElementById(`direct-upload-progress-${id}`);
    const progressChildren = progress.children;
    const url = this.element.dataset.url;

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(imageData)
    }).then((response) => {
      if (!response.ok) { throw response; }
      return response.json();
    }).then(() => {
      progressChildren[0].classList.add('bg-success');
    }).catch((error) => {
      this.log(`fetch error (${id}, ${error})`);
      progress.classList.add('bg-danger');
      progressChildren[0].classList.add('bg-danger');
      progressChildren[1].innerHTML = 'Failed';
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  buildFileUploadRow(file, id) {
    const tr = document.createElement('tr');
    tr.id = `direct-upload-${id}`;
    tr.classList.add('direct-upload');
    if (this.hasFilenameWarningModalTarget && file.name.toLowerCase().startsWith('lot')) {
      $(this.filenameWarningModalTarget).modal('show');
    }
    tr.innerHTML = `
        <td class="text-wrap-anywhere direct-upload-file-name">${file.name}</td>
        <td id="direct-upload-size-${id}" class="text-nowrap">${this.toHumanFileSize(file.size, true)}</td>
        <td class="align-middle w-25">
          <div id="direct-upload-progress-${id}" class="progress bg-secondary position-relative">
            <div class="progress-bar"></div>
            <div class="position-absolute progress-value text-light">0%</div>
          </div>
        </td>
        <td class="align-middle text-center" data-target="portal--image-uploads.removeTableDataWrapper">
          <i class="cursor-pointer fas fa-times text-danger" data-action="click->portal--image-uploads#remove" data-name="${file.name}"></i>
        </td>
      `;
    this.tableBodyTarget.appendChild(tr);
  }

  select() {
    const selectedFiles = Array.from(this.inputTarget.files).filter(file => file.type.match(/image.*/));
    const invalidSelectedFiles = Array.from(this.inputTarget.files).filter(file => !file.type.match(/image.*/));
    if (invalidSelectedFiles.length !== 0) {
      this.alertMessage(`<span class="font-weight-bold">Invalid files removed:</span> ${invalidSelectedFiles.map(file => file.name).join(', ')}`);
    } else {
      this.alertMessage('', true);
    }

    this.selectedFilesCardTarget.hidden = false;
    if (selectedFiles.length === 0) {
      this.addFilesButtonTarget.hidden = false;
      this.startUploadsButtonTarget.disabled = true;
    } else {
      this.addFilesButtonTarget.hidden = true;
      this.startUploadsButtonTarget.disabled = false;
      this.uploadedFileCount = 0;
      this.selectedFileCount = selectedFiles.length;
      this.progressTarget.innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
      this.progressTarget.hidden = false;
      this.selectedFilesCardTarget.hidden = false;
    }

    this.tableBodyTarget.innerHTML = '';
    this.buildFileUploadRows(selectedFiles);
  }

  startAll() {
    this.statusTarget.parentElement.hidden = false;
    super.startAll();
  }
}
